import { createApp } from 'vue';
import App from './App.vue';
import 'devextreme/dist/css/dx.light.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router';
import config from 'devextreme/core/config';


config({
    licenseKey: "ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjRhNGNkNmVmLTZiNzgtNDVlOC04NTM1LTgyZWFiYjNlNTNkZCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.uNewg7cd1ONuH0PBxM1kD2Of5XeAwCMS53D0OTMjiWpB+U42ewE0Rpmiusv/Pyu8ppLRm8xlJEGGXrZ77vT04AiM2XedLTyfaxFOtdI5MR45nGmT7gWoFW6m+kf6cH0PJNXrJw== "
});

const app = createApp(App);

app.use(router);

app.mount('#app');