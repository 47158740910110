<template>
    <NavbarComponent/>
    <LoadingComponent v-if="isLoading"/>
    <div style="margin: 2vw; margin-top: 13vh">
        <div id="token" class="row rounded-div" style="padding-top: 3vh">
            <label for="token" style="font-weight: bold;padding-bottom: 15px;">Administrare tokeni</label>
            <div class="col-6">
                <h3 style="text-align: center">Firme</h3>
                <DxDataGrid
                    :allowColumnReordering='true'
                    style="padding-left: 1vw; padding-right: 1vw; padding-top: 1vh; padding-bottom: 1vh; height: 50vh"
                    :data-source="firms"
                    :show-borders="true"
                    ref="dataGridFirms"
                    keyExpr="id"
                    :allow-column-resizing="true"
                    @saved="saveFirm"
                    @editing-start="onEditingStartFirm"
                    @edit-canceled="restoreFirmAfterCancel"
                >
                    <DxEditing
                        :allow-updating="true"
                        :allow-deleting="true"
                        mode="row"
                    />
                    <DxToolbarDataGrid>
                        <DxItemDataGrid
                            location="before"
                            template="addTemplate"
                        />
                    </DxToolbarDataGrid>
                    <template #addTemplate>
                        <DxButton
                            icon="add"
                            text="Adaugă firmă"
                            @click="() => {this.showFirmPopup = true;}"
                        />
                    </template>


                    <DxFilterRow :visible='true'/>
                    <DxScrolling mode="virtual" row-rendering-mode="virtual"/>

                    <DxColumn dataField="id" caption="ID" :allowEditing="false"/>
                    <DxColumn dataField="name" caption="Nume" :allowEditing="true"/>
                    <DxColumn dataField="cif" caption="CIF" :allowEditing="true"/>
                    <DxColumn dataField="certificateDataId" caption="Token" :allowEditing="true">
                        <DxLookup
                            :data-source="tokens"
                            value-expr="id"
                            display-expr="name"
                        />
                    </DxColumn>

                    <DxScrolling mode="infinite"/>

                </DxDataGrid>
            </div>
            <div class="col-6">
                <h3 style="text-align: center">Tokeni</h3>
                <div class="row" style="padding-top: 5px; padding-bottom: 10px">
                    <div class="col-3">
                        <DxButton
                            icon="add"
                            text="Adaugă token"
                            @click="addToken"
                        />
                    </div>

                </div>
                <DxList
                    :data-source="tokens">
                    <template #item="{ data }">
                        <div class="row">
                            <div class="col-3" style="text-align: left">{{ data.name }}</div>
                            <div class="col-1"></div>
                            <div class="col-3" :style="getExpStyle(data)">
                                Expiră: {{ data.expDate.slice(0, 10) }}
                            </div>
                            <div class="col-1"></div>
                            <div class="col-3">
                                <DxButton style="align: right" @click="refreshToken(data.id)">
                                    <a class="dx-icon-pulldown" style="color: black"/> Refresh
                                </DxButton>
                            </div>

                        </div>

                    </template>
                </DxList>
            </div>
        </div>
    </div>

    <DxPopup
        v-model:visible="showFirmPopup"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="false"
        :show-title="true"
        :width="300"
        :height="420"
        container=".dx-viewport"
        title="Adaugă o firmă"
        @hidden="closeFirmPopup()"
    >
        <form @submit.prevent="submitFirm()">
            <div class="form-group">
                <label for="username">Denumire</label>
                <input type="text" id="username" v-model="name" placeholder="Introduceți denumirea firmei"
                       :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <label for="password">CIF</label>
                <input type="text" id="password" v-model="cif" placeholder="Introduceți CIF-ul firmei"
                       :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <label for="token">Token</label>
                <DxLookup2 id="token"
                           :items="tokens"
                           v-model="selectedToken"
                           display-expr="name">
                    <DxDropDownOptions :show-title="false"/>
                </DxLookup2>
            </div>
            <div class="form-group">
                <span v-if="error" style="color: red">{{ this.errorMessage }}</span>
            </div>
            <button type="submit" class="submit-button"
                    :class="{ inactive: isNullOrWhitespace(name) || isNullOrWhitespace(cif) || isLoading}">
                <i class="dx-icon-plus"></i>
                Adaugă
            </button>
        </form>
    </DxPopup>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent";
import LoadingComponent from "@/components/LoadingComponent";
import {DxLookup as DxLookup2, DxDropDownOptions} from 'devextreme-vue/lookup';
import DxToolbar, {DxItem} from 'devextreme-vue/toolbar';
import {
    DxList
} from "devextreme-vue/list";
import {
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxScrolling,
    DxToolbar as DxToolbarDataGrid,
    DxItem as DxItemDataGrid,
    DxLookup,
    DxEditing
} from "devextreme-vue/data-grid";
import {DxButton} from "devextreme-vue/button"
import axios from "axios";
import config from "@/configuration";
import {DxPopup} from "devextreme-vue/popup";

export default {
    name: "AdministerTokensComponent",
    components: {
        DxLookup2,
        DxDropDownOptions,
        DxPopup,
        DxEditing,
        DxButton,
        LoadingComponent,
        NavbarComponent,
        DxDataGrid,
        DxScrolling,
        DxColumn,
        DxFilterRow,
        // eslint-disable-next-line vue/no-unused-components
        DxToolbar,
        // eslint-disable-next-line vue/no-unused-components
        DxItem,
        DxLookup,
        DxList,
        DxToolbarDataGrid,
        DxItemDataGrid
    },
    data() {
        return {
            isLoading: true,
            firms: [],
            tokens: [],
            showFirmPopup: false,
            unmodifiedFirm: null,
            name: '',
            cif: '',
            selectedToken: null
        }
    },
    methods: {
        parseDate(dateString) {
            const b = dateString.split(/\D+/);
            const offsetMult = dateString.indexOf('+') !== -1 ? -1 : 1;
            const hrOffset = offsetMult * (+b[7] || 0);
            const minOffset = offsetMult * (+b[8] || 0);
            return new Date(Date.UTC(+b[0], +b[1] - 1, +b[2], +b[3] + hrOffset, +b[4] + minOffset, +b[5], +b[6] || 0));
        },
        getExpStyle(data) {
            let time = this.parseDate(data.expDate);
            let now = new Date();

            let diffDays = (time - now) / (3600 * 24 * 1000);

            if (diffDays >= 10) {
                return "color: green";
            } else if (diffDays < 10 && diffDays >= 5) {
                return "color: coral";
            } else {
                return "color: red";
            }

        },
        isNullOrWhitespace(input) {
            return !input || !input.trim();
        },
        isNumber(input) {
            return /^\d+$/.test(input);
        },
        async refreshToken(id) {

            try {
                this.isLoading = true;
                await axios.get(`${config.API_URL}/eFactura/RefreshToken/${id}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });

                await this.refreshTokens()

                alert("Token reactualizat!")
            } catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la reactualizarea tokenului!");
                }
            } finally {
                this.isLoading = false;
            }
        },
        async submitFirm() {
            if (!this.isNumber(this.cif)) {
                this.errorMessage = 'CIF-ul poate conține doar cifre!';
                this.error = true;
                return;
            }
            try {
                this.isLoading = true;
                await axios.post(`${config.API_URL}/Data/AddFirm`,
                    {
                        Name: this.name,
                        CIF: this.cif,
                        CertificateDataId: this.selectedToken.id
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.showFirmPopup = false;
                this.error = false;
                this.errorMessage = '';
                this.name = '';
                this.cif = '';
                this.selectedToken = null;
                await this.refreshFirms();
            } catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    this.errorMessage = error?.response?.data;
                    this.error = true;
                }

            } finally {
                this.isLoading = false;
            }

        },
        async closeFirmPopup() {
            this.error = false;
            this.errorMessage = '';
            this.name = '';
            this.cif = '';
            this.selectedToken = null;
            await this.refreshFirms();
        },
        async refreshFirms() {
            try {
                this.isLoading = true;
                let response = await axios.get(`${config.API_URL}/Data/GetFirms`,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.firms = response.data;
            } catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la încărcarea datelor!");
                }
            } finally {
                this.isLoading = false;
            }
        },
        async saveFirm(e) {
            let firm = null;
            let firmModifiedEvent = e.changes.filter(x => x.type === 'update');
            let firmRemovedEvent = e.changes.filter(x => x.type === 'remove');

            if (firmRemovedEvent.length !== 0) {
                try {

                    this.isLoading = true;
                    await axios.delete(`${config.API_URL}/Admin/RemoveFirm?firmId=${firmRemovedEvent[0].key}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${this.token}`
                            }
                        });
                } catch (error) {
                    if (error?.response?.status === 401) {
                        alert("Autentificarea a expirat!");
                        this.$router.push('/Login');
                    }
                } finally {
                    this.isLoading = false;
                    // eslint-disable-next-line no-unsafe-finally
                    return;
                }
            }

            if (firmModifiedEvent.length === 0) {
                firm = this.firms.find(x => x.id === this.unmodifiedFirm.id);
            } else {
                firm = firmModifiedEvent[0].data;
            }


            try {

                this.isLoading = true;
                await axios.put(`${config.API_URL}/Data/UpdateFirmInfo`,
                    {
                        Id: firm.id,
                        Name: firm.name,
                        CIF: firm.cif,
                        CertificateDataId: firm.certificateDataId
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.unmodifiedFirm = null;
            } catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert(error?.response?.data);
                    e.cancel = true;
                    let rowIndex = firm.id;
                    this.$refs.dataGridFirms.instance.editRow(this.findFirmRowIndexByKey(rowIndex));
                }
            } finally {
                this.isLoading = false;
            }
        },
        onEditingStartFirm(e) {
            if (this.unmodifiedFirm === null) {

                this.unmodifiedFirm = JSON.parse(JSON.stringify(e.data));
            }
        },
        restoreFirmAfterCancel() {
            for (let i = 0; i < this.firms.length; i++) {
                if (this.firms[i].id === this.unmodifiedFirm.id) {
                    this.firms[i] = this.unmodifiedFirm;
                    this.$refs["dataGridFirms"].instance.refresh();

                    this.unmodifiedFirm = null;
                    return;
                }
            }
        },
        findFirmRowIndexByKey(key) {
            return this.firms.findIndex(item => item.id === key);
        },

        async addToken() {
            try {

                this.isLoading = true;
                let result = await axios.get(`${config.API_URL}/eFactura/GenerateAddTokenRequest`,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                let idRequest = result.data;

                let url = `${config.API_URL}/eFactura/InitializeCertificate?idRequest=${idRequest}`;
                window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            } catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                }
            } finally {
                this.isLoading = false;
            }
        },
        async refreshTokens() {
            try {

                this.isLoading = true;
                let result = await axios.get(`${config.API_URL}/Data/GetTokens/`,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.tokens = result.data;
            } catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la aducerea tokenilor!");
                }
            } finally {
                this.isLoading = false;
            }
        }

    },
    async mounted() {
        this.token = localStorage.getItem("Token");
        try {

            this.isLoading = true;
            let result = await axios.get(`${config.API_URL}/Data/GetFirms/`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
            this.firms = result.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                alert("Autentificarea a expirat!");
                this.$router.push('/Login');
            } else {
                alert("Eroare la aducerea utilizatorilor!");
            }
        } finally {
            this.isLoading = false;
        }
        try {

            this.isLoading = true;
            let result = await axios.get(`${config.API_URL}/Data/GetTokens/`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
            this.tokens = result.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                alert("Autentificarea a expirat!");
                this.$router.push('/Login');
            } else {
                alert("Eroare la aducerea tokenilor!");
            }
        } finally {
            this.isLoading = false;
        }
    },
}
</script>

<style scoped>
.rounded-div {
    border: 1px solid black; /*Adds a border */
    border-radius: 10px; /* Rounds the corners */
    padding: 10px; /* Adds some padding inside the div */
    text-align: center; /* Centers the text inside */
}

.form-group {
    margin-bottom: 20px; /* Increased margin to create more space between form elements */
    text-align: left;
}


input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 12px; /* Increased padding inside input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 12px; /* Increased padding inside the button */
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button:hover {
    background-color: #218838;
}

.inactive {
    opacity: 0.5; /* Makes it look inactive */
    pointer-events: none; /* Disables all interactions */
}
</style>