<template>
    <RouterView />
</template>

<script>
import { RouterView } from 'vue-router';
// eslint-disable-next-line no-unused-vars
import DxMenu from 'devextreme-vue/menu';
import axios from "axios";
import config from "@/configuration";
//am pastrat importul asta ca sa se aplice font-ul

export default {
    name: 'App',
    components: {
        RouterView,
        // DxTreeView,

    },
    async mounted() {
        this.token = localStorage.getItem("Token");

        if (this.token === null) {
            this.$router.push("/Login");
            return;
        }

        //daca tokenul a expirat, intoarcem utilizatorul la login
        try {
            await axios.get(`${config.API_URL}/Authentication/TestToken`,
                {
                    headers : {
                        'Authorization': `Bearer ${this.token}`
                    }
                });

        }
        catch (error) {
            this.$router.push("/Login");
            return;
        }

    },
    data() {
        return {
            routes: [
                {
                    "name": "Login",
                    "route": "/login"
                },
            ],
            token: null,
        }
    },
    methods: {
        changeSelection({ itemData }) {

            this.$router.push(itemData.route);
        },
    },
};
</script>

<style scoped>

.form {
    margin-left: 3px;
}

.form > div:first-child {
    margin-right: 320px;
}

</style>
