<template>
    <nav class="navbar_c">
        <div class="navbar-container_c">
            <div class="navbar-logo_c">
                <a href="/">
                    <img style="width: 50px; height:50px; padding-right: 7px" src="../assets/logo.png"/>
                    ERA eFactura {{firmName}}
                </a>
            </div>
            <ul v-if="($attrs['is-logged-in'] ?? 'true') === 'true'" class="navbar-menu_c">
                <li><a href="/">Acasă</a></li>
                <li v-if="role === 'ADMIN'"><a href="/AdministerUsers">Administrare utilizatori</a></li>
                <li v-if="role === 'ADMIN'"><a href="/AdministerTokens">Administrare tokeni</a></li>
                <li><a href="/SelectTokenAndFirm">Modifică configurarea</a></li>
<!--                <li><a href="#services">Services</a></li>-->
                <li>
                    <a class="logout-button" href="/Login">
                        <i class="dx-icon-clear"></i>
                        Deconectare
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";

export default {
    name: 'NavbarComponent',
    data() {
        return {
            firmName: '',
            token: '',
            role: ''
        }
    },
    mounted() {
        if ((this.$attrs['is-logged-in'] ?? 'true') === 'true') {
            if (localStorage.getItem("Firm") !== null) {
                let firmSelected = JSON.parse(localStorage.getItem("Firm"));
                this.firmName = `- ${firmSelected.name}`;

            }

            this.token = localStorage.getItem("Token");
            this.role = VueJwtDecode.decode(this.token).Role;

        }


    }
};
</script>

<style scoped>
.navbar_c {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px; /* Rounded bottom corners */
    z-index: 1000; /* Ensures the navbar is above other content */
}

.navbar-container_c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px; /* Padding inside the navbar */
}

.navbar-logo_c a {
    color: #000;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center; /* Center the content vertically */
}

.navbar-menu_c {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
}

.navbar-menu_c li {
    display: inline;
}

.navbar-menu_c a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

.navbar-menu_c a:hover {
    text-decoration: underline;
}

.logout-button {
    width: 100%;
    padding: 12px; /* Increased padding inside the button */
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.logout-button:hover {
    background-color: darkred;
}
</style>
