<template>
    <NavbarComponent/>
    <LoadingComponent v-if="isLoading"></LoadingComponent>
    <div style="margin: 2vw; margin-top: 13vh">
        <div id="acces" class="row rounded-div" style="padding-top: 3vh">
            <label for="acces" style="font-weight: bold;padding-bottom: 15px;">Administrare acces</label>
            <div class="col-6">
                <h3 style="text-align: center">Utilizatori</h3>
                <DxDataGrid
                    :allowColumnReordering='true'
                    style="padding-left: 1vw; padding-right: 1vw; padding-top: 1vh; padding-bottom: 1vh; height: 50vh"
                    :data-source="users"
                    :show-borders="true"
                    ref="dataGridUsers"
                    :allow-column-resizing="true"
                    @saved="saveUser"
                    @editing-start="onEditingStartUser"
                    @edit-canceled="restoreUserAfterCancel"
                    :selection="{ mode: 'single' }"
                    @selection-changed="onUserSelected"
                >

                    <DxToolbar>
                        <DxItem
                            location="before"
                            template="addTemplate"
                        />
                        <DxItem
                            location="before"
                            template="resetPassword"
                        />
                    </DxToolbar>
                    <template #addTemplate>
                        <DxButton
                            icon="add"
                            text="Înregistrează utilizator"
                            @click="() => {this.showRegisterPopup = true;}"
                        />
                    </template>
                    <template #resetPassword>
                        <DxButton
                            icon="revert"
                            text="Resetează parola"
                            @click="() => {this.showResetPasswordPopup = true; this.titleResetPassword = 'Resetare parolă ' + this.selectedUser.username; }"
                            :disabled="selectedUser === null"
                        />
                    </template>
                    <DxFilterRow :visible='true'/>
                    <!--                    <DxColumn dataField="id" caption="Nr. Crt."/>-->
                    <DxScrolling mode="virtual" row-rendering-mode="virtual"/>

                    <DxEditing
                        :allow-updating="true"
                        mode="row"
                    />

                    <DxColumn dataField="id" caption="ID" :allowEditing="false"/>
                    <DxColumn dataField="username" caption="Nume de utilizator"/>
                    <DxColumn dataField="email" caption="E-mail"/>
                    <DxColumn dataField="role" caption="Rol">
                        <DxLookup
                            :data-source="roles"
                            display-expr="name"
                            value-expr="value"
                        />
                    </DxColumn>

                    <DxColumn dataField="active" caption="Cont Activ"/>
                    <DxScrolling mode="infinite"/>

                </DxDataGrid>


            </div>
            <div class="col-6">
                <h3 style="text-align: center">Firme asignate</h3>
                <DxDataGrid
                    :allowColumnReordering='true'
                    style="padding-left: 1vw; padding-right: 1vw; padding-top: 1vh; padding-bottom: 1vh; height: 50vh"
                    :data-source="firmsAssigned"
                    :show-borders="true"
                    ref="dataGridFirmsAssigned"
                    keyExpr="id"
                    :allow-column-resizing="true"
                    @saved="onAssignmentChanged"
                >
                    <DxEditing
                        :allow-updating="true"
                        mode="cell"
                    />


                    <DxFilterRow :visible='true'/>
                    <DxScrolling mode="virtual" row-rendering-mode="virtual"/>

                    <DxColumn dataField="name" caption="Nume" :allowEditing="false"/>
                    <DxColumn dataField="cif" caption="CIF" :allowEditing="false"/>
                    <DxColumn dataField="isAssigned" caption="Acces Permis" :allowEditing="true"/>

                    <DxScrolling mode="infinite"/>

                </DxDataGrid>
            </div>
        </div>

    </div>

    <DxPopup
        v-model:visible="showRegisterPopup"
        width="400"
        height="65vh"
        :drag-enabled="false"
        :hide-on-outside-click="false"
        :show-close-button="!isLoading"
        :show-title="true"
        container=".dx-viewport"
        title="Înregistrare Utilizator"
        @hidden="clearRegisterUserForm"
    >
        <form @submit.prevent="onRegisterUser()">
            <div class="form-group">
                <label for="username">Nume de utilizator</label>
                <input type="text" id="username" v-model="username" placeholder="Introduceți numele de utilizator" :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <label for="email">E-mail</label>
                <input type="email" id="email" v-model="email" placeholder="Introduceți e-mail-ul" :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <label for="password">Parolă</label>
                <input type="password" id="password" v-model="password" placeholder="Introduceți parola" :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <label for="role">Rol</label>
                <DxSelectBox
                    id="role"
                    v-model="role"
                    :items="roles"
                    display-expr="name"
                    value-expr="value"
                />
            </div>
            <div class="form-group">
                <span v-if="error" style="color: red">{{this.errorMessage}}</span>
            </div>
            <div class="form-check">

                <input class="form-check-input" type="checkbox" v-model="active" id="active">
                <label for="active">Activ</label>
            </div>
            <div class="form-group" style="padding-top: 1vh">
                <button type="submit" class="submit-button" :class="{ inactive: isLoading}">
                    <i class="dx-icon-plus"></i>
                    Înregistrează
                </button>
            </div>
        </form>

    </DxPopup>


    <DxPopup
        v-model:visible="showResetPasswordPopup"
        width="400"
        height="35vh"
        :drag-enabled="false"
        :hide-on-outside-click="false"
        :show-close-button="!isLoading"
        :show-title="true"
        container=".dx-viewport"
        :title="titleResetPassword"
        @hidden="clearRegisterUserForm"
    >
        <form @submit.prevent="onResetPassword()">
            <div class="form-group">
                <label for="password_new">Parolă nouă</label>
                <input type="password" id="password_new" v-model="password" placeholder="Introduceți parola" :class="{ inactive: isLoading }"/>
            </div>

            <div class="form-group">
                <span v-if="error" style="color: red">{{this.errorMessage}}</span>
            </div>
            <div class="form-group" style="padding-top: 1vh">
                <button type="submit" class="submit-button" :class="{ inactive: isLoading}">
                    <i class="dx-icon-revert"></i>
                    Resetează
                </button>
            </div>
        </form>

    </DxPopup>


</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import axios from "axios";
import config from "@/configuration";
import NavbarComponent from "@/components/NavbarComponent";
import { DxButton } from 'devextreme-vue/button';
import { DxPopup } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxEditing,
    DxToolbar,
    DxItem,
} from 'devextreme-vue/data-grid';
export default {
    name: "AdministerUsersComponent",
    components: {
        NavbarComponent,
        LoadingComponent,
        DxDataGrid,
        DxScrolling,
        DxColumn,
        DxFilterRow,
        DxLookup,
        DxEditing,
        DxToolbar,
        DxItem,
        DxButton,
        DxPopup,
        // eslint-disable-next-line vue/no-unused-components
        DxTextBox,
        DxSelectBox
    },
    data() {
        return {
            isLoading: true,
            users: [],
            token: '',
            roles: [
              { value: 'ADMIN', name: 'ADMIN'},
              { value: 'ACCOUNTANCY', name: 'CONTABILITATE'},
              { value: 'SALES', name: 'VÂNZĂRI'},
            ],
            unmodifiedUser: null,
            firmsAssigned: [],
            selectedUser: null,
            showRegisterPopup: false,
            error: false,
            errorMessage: '',
            //pentru form adaugare user
            username: '',
            email: '',
            password: '',
            role: '',
            active: true,
            //------------------------
            showResetPasswordPopup: false,
            titleResetPassword: '',
            //pentru popup resetare parola
            //----------------------------
        }

    },
    async mounted() {
        this.token = localStorage.getItem("Token");
        try {

            this.isLoading = true;
            let result = await axios.get(`${config.API_URL}/Admin/GetUsers/`,
                {
                    headers : {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
            this.users = result.data;
        }
        catch (error) {
            if (error?.response?.status === 401) {
                alert("Autentificarea a expirat!");
                this.$router.push('/Login');
            } else {
                alert("Eroare la aducerea utilizatorilor!");
            }
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        clearRegisterUserForm() {
            this.error = false;
            this.errorMessage = "";
            this.username = "";
            this.email = "";
            this.password = "";
            this.role = "";
            this.active = true;
        },
        async onResetPassword() {
            let body = {
                UserId: this.selectedUser.id,
                Password: this.password
            };

            try {

                this.isLoading = true;
                await axios.put(`${config.API_URL}/Admin/ResetPasswordForUser`,
                    body,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                alert("Parolă resetată cu succes!");
                this.showResetPasswordPopup = false;

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    this.error = true;
                    this.errorMessage = error?.response?.data;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async onRegisterUser() {
            let body = {
                Username: this.username,
                Email: this.email,
                Password: this.password,
                Role: this.role,
                Active: this.active
            };

            try {

                this.isLoading = true;
                let result = await axios.post(`${config.API_URL}/Authentication/RegisterUser`,
                    body,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.users.push(result.data);
                alert("Utilizator înregistrat cu succes!");
                this.showRegisterPopup = false;
                this.$refs["dataGridUsers"].instance.refresh();

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    this.error = true;
                    this.errorMessage = error?.response?.data;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async saveUser(e) {
            let user = null;
            let userModifiedEvent = e.changes.filter(x => x.type === 'update');

            if (userModifiedEvent.length === 0) {
                user = this.users.find(x => x.id === this.unmodifiedUser.id);
            } else {
                user = userModifiedEvent[0].data;
            }


            try {

                this.isLoading = true;
                await axios.put(`${config.API_URL}/Admin/UpdateUserInfo`,
                    {
                        Id: user.id,
                        Username: user.username,
                        Email: user.email,
                        Active: user.active,
                        Role: user.role
                    },
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.unmodifiedUser = null;
            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    console.log(error);
                    alert(error?.response?.data);
                    e.cancel = true;
                    let rowIndex = user.id;
                    this.$refs.dataGridUsers.instance.editRow(this.findUserRowIndexByKey(rowIndex));
                }
            } finally {
                this.isLoading = false;
            }


        },

        onEditingStartUser(e) {

            if (this.unmodifiedUser === null) {

                this.unmodifiedUser = JSON.parse(JSON.stringify(e.data));
            }

        },

        restoreUserAfterCancel(){
            for(let i = 0; i < this.users.length; i++) {
                if (this.users[i].id === this.unmodifiedUser.id) {
                    this.users[i] = this.unmodifiedUser;
                    this.$refs["dataGridUsers"].instance.refresh();

                    this.unmodifiedUser = null;
                    return;
                }
            }
        },

        findUserRowIndexByKey(key) {
            return this.users.findIndex(item => item.id === key);
        },

        async onUserSelected(e) {
            try {

                this.isLoading = true;
                this.selectedUser = e.selectedRowsData[0];
                let result = await axios.get(`${config.API_URL}/Admin/GetFirmsForUser?UserId=${this.selectedUser.id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.firmsAssigned = result.data;

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la aducerea firmelor asignate!");
                }
            } finally {
                this.isLoading = false;
            }

        },
        async onAssignmentChanged(e) {
            try {

                this.isLoading = true;
                await axios.put(`${config.API_URL}/Admin/ModifyAccesToFirm?` +
                    `UserId=${this.selectedUser.id}&FirmId=${e.changes[0].data.id}&delete=${!e.changes[0].data.isAssigned}`,
                    null,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    // console.log(error);
                    e.changes[0].data.isAssigned = !e.changes[0].data.isAssigned;
                    alert("Eroare la modificarea accesului!");
                }
            } finally {
                this.isLoading = false;
            }
        },

    }
}
</script>

<style scoped>
.rounded-div {
    border: 1px solid black;  /*Adds a border */
    border-radius: 10px; /* Rounds the corners */
    padding: 10px; /* Adds some padding inside the div */
    text-align: center; /* Centers the text inside */
}

.form-group {
    margin-bottom: 20px; /* Increased margin to create more space between form elements */
    text-align: left;
}

label {
    display: block;
    margin-bottom: 8px; /* Increased margin between label and input field */
    font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 12px; /* Increased padding inside input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 12px; /* Increased padding inside the button */
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button:hover {
    background-color: #218838;
}

.inactive {
    opacity: 0.5; /* Makes it look inactive */
    pointer-events: none; /* Disables all interactions */
}
</style>